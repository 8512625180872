import './css/style.css';
import React from 'react';
import NavBar from './global/Navbar';
import NavbarM from './global/NavbarM';
import Banner from './index/Banner';
import ServicesTab from './index/ServicesTab';
import Blog from './index/Blog';
import Guide from './index/Guide';
import Footer from './index/Footer';
import SmallBanner from './index/SmallBanner';
import Testify from './index/Testify';

const Home = () => {
  const RenderMobile = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
      const handleResizeWindow = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResizeWindow);
      return () => {
        window.removeEventListener("resize", handleResizeWindow);
      };
    }, []);
    if (width >= 1024) return (<NavBar />);
    return (<NavbarM/>);
  }
  return (
    <>
      {RenderMobile()}
      <Banner />
      <ServicesTab />
      <Blog />
      <Guide />
      <SmallBanner />
      <Testify />
      <Footer />
    </>
  )
}

export default Home;
