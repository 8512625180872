import React, { useState } from "react";
import blogs from "../../database/blog.json"

const Blog = () => {
    const defaultStyle = {
        "boxSizing": "border-box",
        "display": "block",
        "overflow": "hidden",
        "width": "initial",
        "height": "initial",
        "background": "none",
        "opacity": "1",
        "border": "0",
        "margin": "0",
        "padding": "0",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    }
    const defaultBlogImg = {
        "position": "absolute",
        "inset": "0px",
        "boxSizing": "border-box",
        "padding": "0px",
        "border": "none",
        "margin": "auto",
        "display": "block",
        "width": "0px",
        "height": "0px",
        "minWidth": "100%",
        "maxWidth": "100%",
        "minHeight": "100%",
        "maxHeight": "100%",
        "objectFit": "cover"
    }
    const value = {
        0: "Trade",
        1: "Coffee"
    }
    const article = ["Trade", "Coffee"]
    const [active, setActive] = useState(0);
    const [filter_blog, setFilterBlog] = useState(blogs.filter(i => i.article === value[active]));
    const toggle = index => event => {
        setActive(index);
        setFilterBlog(blogs.filter(i => i.article === value[active]));
    };
    const renderButton = () => {
        let buttons = [];
        for (let i = 0; i < article.length; i++) {
            buttons.push(
                <button key={"blogs--tab--" + i} onClick={toggle(i)} aria-controls={"tabs--panel--" + i} role="tab" tabIndex={i} className="inline-block whitespace-nowrap px-3 text-base font-semibold outline-none ring-0" data-orientation="horizontal" type="button">
                    <span className={`relative block py-1 before:absolute before:left-0 before:bottom-0 before:h-0.5 hover:text-bluesky-600 ${active === i ? `text-bluesky-600 before:w-full before:bg-bluesky-600` : `text-gray-700 before:w-0 before:bg-transparent`}`}>{article[i]}</span>
                </button>
            )
        }
        return buttons;
    }
    const renderBlog = () => {
        let blog = []
        let length = filter_blog.length;
        if (filter_blog.length > 8) length = 8;
        for (let i = 0; i < length; i++) {
            blog.push(
                <div key={`blog--blogs-${i}`} className="group h-full cursor-pointer transition-shadow md:overflow-hidden md:rounded-lg md:bg-white md:shadow-md md:hover:shadow-lg">
                    <div className="h-full">
                        <div className="flex h-full flex-col">
                            <div className="sm:w-full">
                                <div className="overflow-hidden rounded-lg bg-gray-200 md:rounded-none">
                                    <div className="relative block aspect-1 sm:hidden">
                                        <span style={defaultStyle}>
                                            <img alt={filter_blog[i].title} src={filter_blog[i].cover_image} decoding="async" data-nimg="fill" style={defaultBlogImg} />
                                            <noscript></noscript>
                                        </span>
                                    </div>
                                    <div className="relative hidden sm:block" style={{ "aspectRatio": "508 / 244" }}>
                                        <span style={defaultStyle}>
                                            <img alt={filter_blog[i].title} src={filter_blog[i].cover_image} decoding="async" data-nimg="fill" style={defaultBlogImg} />
                                            <noscript></noscript>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative flex flex-1 flex-col sm:w-full sm:flex-auto sm:pb-7 md:px-4">
                                <div className="pt-2.5 pr-2 text-sm line-clamp-2 group-hover:text-bluesky-600 landing:text-lpText sm:pt-3 sm:pb-2 sm:pr-0 sm:text-md sm:font-semibold sm:leading-snug sm:line-clamp-none md:landing:text-gray-900">
                                    {filter_blog[i].title}
                                </div>
                                <div className="hidden items-center sm:absolute sm:bottom-2 sm:flex md:left-4">
                                    <span className="mr-2 inline-block text-tiny font-bold uppercase text-bluesky-500 hover:text-bluesky-600">
                                        {filter_blog[i].article}
                                    </span>
                                    <span className="inline-block text-xs text-gray-500">· {filter_blog[i].publish}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return blog;
    }
    return (
        <section className="bg-gray-50 py-8 sm:py-10">
            <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div className="mb-5 text-center md:mb-8">
                    <h2 className="text-2xl font-bold landing:text-lpTitle lg:text-3xl text-bluesky-600">Asia Target Update</h2>
                </div>
                <div data-reach-tabs="" data-orientation="horizontal">
                    <div className="relative -mx-5 mb-7 sm:mx-0">
                        <div role="tablist" aria-orientation="horizontal" className="relative -ml-2 block overflow-scroll whitespace-nowrap px-5 text-center sm:ml-0">
                            {renderButton()}
                        </div>
                    </div>
                    <div data-reach-tab-panels="">
                        <div className="block">
                            <div className="grid grid-cols-2 gap-y-5 gap-x-3 sm:gap-6 lg:grid-cols-4">
                                {renderBlog()}
                            </div>
                            <div className="col-span-4 mt-7 text-center">
                                <a className="mx-auto flex w-fit items-center justify-center rounded-full border border-bluesky-600 px-5 pt-1.5 pb-1 font-semibold text-bluesky-700 transition-all hover:bg-bluesky-50 hover:text-bluesky-800" href="/tin-tuc#home">Read more <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="ml-1 w-4">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;