import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './index/Footer';

const NotFound = () => {
    return (
        <>
            <section class="page_404">
                <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1 text-center">
                        <div class="four_zero_four_bg"></div>
                        <div class="contant_box_404">
                            <h3 class="h2">
                                The page is still underconstructed or you are lost somehow...
                            </h3>
                            <Link to="/" className="mx-auto flex w-fit items-center justify-center rounded-full border border-bluesky-600 px-5 pt-1.5 pb-1 font-semibold text-white bg-bluesky-700 transition-all">
                                Go Home
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
};

export default NotFound;