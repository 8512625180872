import React, { useState } from "react";

const Testify = () => {
    const [active, setActive] = useState(0);
    const toggle = index => event => setActive(index);
    const tabContent = [
        {
            title: "Content 1",
            content: {
                1: "Some interesting content to put here and make it interesting 1",
                2: "Some interesting content to put here and make it interesting 1",
                3: "Some interesting content to put here and make it interesting 1"
            }
        }, {
            title: "Content 2",
            content: {
                1: "Some interesting content to put here and make it interesting 2",
                2: "Some interesting content to put here and make it interesting 2",
                3: "Some interesting content to put here and make it interesting 2"
            }
        }, {
            title: "Content 3",
            content: {
                1: "Some interesting content to put here and make it interesting 3",
                2: "Some interesting content to put here and make it interesting 3",
                3: "Some interesting content to put here and make it interesting 3"
            }
        },
    ]
    const renderTab = () => {
        let tab = [];
        for (let i = 0; i < tabContent.length; i++) {
            tab.push(
                <div key={`testify--tab--${i}`} aria-labelledby={`testify--tab--${i}`} role="tabpanel" tabIndex={i} className={`${active === i ? "block" : "hidden"}`}>
                    <div className="grid items-center md:grid-cols-2">
                        <div className="order-0 md:order-2">
                            <img alt="Bảo chứng ngân hàng" src="./splash1.png" width="500" height="434" loading="lazy" className="img-fluid px-md-0 mx-auto block px-3" />
                        </div>
                        <div className="order-1 pt-8">
                            <div className="mb-3 mr-4 lg:mb-4 lg:ml-0 lg:mr-4">
                                <div className="flex items-start">
                                    <div className="mb-0 ml-3">{tabContent[i].content[1]}</div>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 lg:mb-4 lg:ml-0 lg:mr-4">
                                <div className="flex items-start">
                                    <div className="mb-0 ml-3">{tabContent[i].content[2]}</div>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 lg:mb-4 lg:ml-0 lg:mr-4">
                                <div className="flex items-start">
                                    <div className="mb-0 ml-3">{tabContent[i].content[3]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return tab;
    }
    return (
        <section className="testify">
            <div className="py-8 md:py-10 lg:py-14 landing:bg-transparent bg-white" style={{ "--grad-rgb-color": "255 255 255" }}>
                <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                    <div id="section-html" className="mb-5 text-center md:mb-8">
                        <h2 className="text-2xl font-bold landing:text-lpTitle lg:text-3xl text-bluesky-600">
                            Something good about Asia Target to testify
                        </h2>
                    </div>
                    <div>
                        <div className="warp-scroll">
                            <div className="relative ">
                                <div className="flex w-full overflow-scroll pl-5 md:justify-center md:pl-0">
                                    <button onClick={toggle(0)} className={`mx-4 flex cursor-pointer whitespace-nowrap py-1 font-semibold text-gray-700 transition ${active === 0 ? "text-bluesky-600 border-b-2 border-bluesky-600" : ""} `}>{tabContent[0].title}</button>
                                    <button onClick={toggle(1)} className={`mx-4 flex cursor-pointer whitespace-nowrap py-1 font-semibold text-gray-700 transition ${active === 1 ? "text-bluesky-600 border-b-2 border-bluesky-600" : ""} `}>{tabContent[1].title}</button>
                                    <button onClick={toggle(2)} className={`mx-4 flex cursor-pointer whitespace-nowrap py-1 font-semibold text-gray-700 transition ${active === 2 ? "text-bluesky-600 border-b-2 border-bluesky-600" : ""} `}>{tabContent[2].title}</button>
                                </div>
                            </div>
                        </div>
                        <div data-reach-tabs="" data-orientation="horizontal">
                            <div className="pt-8 md:pt-4" data-reach-tab-panels="">
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testify;