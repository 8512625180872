import React from "react";
import NavBarNS from './global/NavbarNS';
import NavbarM from './global/NavbarM';
import Footer from "./index/Footer";
import BlogsList from "./blog/BlogsList";

const Blog = () => {
    const RenderMobile = () => {
        const [width, setWidth] = React.useState(window.innerWidth);
        React.useEffect(() => {
            const handleResizeWindow = () => setWidth(window.innerWidth);
            window.addEventListener("resize", handleResizeWindow);
            return () => {
                window.removeEventListener("resize", handleResizeWindow);
            };
        }, []);
        if (width >= 1024) return (<NavBarNS />);
        return (<NavbarM />);
    }
    return (
        <>
        { RenderMobile() }
        <BlogsList />
        <Footer />
        </>
    );
};

export default Blog;
