import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavBar_m = () => {
    const [hide, setHide] = useState(true);
    const toggle = () => {
        setHide(s => !s);
    };
    const renderMenu = () => {
        return (
            <div className="mobile-menu__content fadeInDown relative z-40 bg-gray-100 shadow-sm">
                <div className="mb-10">
                    <div className="divide-y divide-gray-200 bg-white" data-reach-accordion="">
                        <div className="block cursor-pointer text-gray-600 hover:text-gray-900 " data-reach-accordion-item="" data-state="collapsed">
                            <Link to="/">
                                <button aria-controls="panel--10--2" aria-expanded="false" className="block w-full text-left focus:outline-none" data-reach-accordion-button="" data-state="collapsed" id="button--10--2">
                                    <div className="jsx-17663d1d95832e81 relative block px-4 py-3 ">
                                        Home
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div className="block cursor-pointer text-gray-600 hover:text-gray-900 " data-reach-accordion-item="" data-state="collapsed">
                            <Link to="/services">
                                <button aria-controls="panel--10--0" aria-expanded="false" className="block w-full text-left focus:outline-none" data-reach-accordion-button="" data-state="collapsed" id="button--10--0">
                                    <div className="jsx-17663d1d95832e81 relative block px-4 py-3 ">
                                        Services
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="jsx-17663d1d95832e81 icon absolute right-4 top-4 inline  h-5 w-5 text-gray-500">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg> */}
                                    </div>
                                </button>
                            </Link>
                            {/* <div role="region" aria-labelledby="button--10--0" data-reach-accordion-panel="" data-state="collapsed" id="panel--10--0" hidden="">
                                <div className="jsx-17663d1d95832e81 mobile-menu__collapse grid grid-cols-2 gap-x-2 bg-gray-100 px-3 pt-1 shadow-inner">
                                    <a href="https://momo.vn/chuyen-tien" className="jsx-17663d1d95832e81 flex flex-row flex-nowrap items-center border-b border-gray-200 py-3 hover:bg-pink-100">
                                        <div className="jsx-17663d1d95832e81 flex-none pr-1 ">
                                            <img alt="" src="https://homepage.momocdn.net/styles/desktop/images/tienich/icon1.svg" className="jsx-17663d1d95832e81 w-5 " />
                                        </div>
                                        <div className="jsx-17663d1d95832e81 flex-1 ">
                                            <div className="jsx-17663d1d95832e81 whitespace-nowrap text-tiny text-gray-700 group-hover:text-pink-700 ">Chuyển - nhận tiền</div>
                                        </div>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        <div className="block cursor-pointer text-gray-600 hover:text-gray-900 " data-reach-accordion-item="" data-state="collapsed">
                            <Link to="/services">
                                <button aria-controls="panel--10--2" aria-expanded="false" className="block w-full text-left focus:outline-none" data-reach-accordion-button="" data-state="collapsed" id="button--10--2">
                                    <div className="jsx-17663d1d95832e81 relative block px-4 py-3 ">
                                        About
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div className="block cursor-pointer text-gray-600 hover:text-gray-900 " data-reach-accordion-item="" data-state="collapsed">
                            <Link to="/services">
                                <button aria-controls="panel--10--2" aria-expanded="false" className="block w-full text-left focus:outline-none" data-reach-accordion-button="" data-state="collapsed" id="button--10--2">
                                    <div className="jsx-17663d1d95832e81 relative block px-4 py-3 ">
                                        Blogs
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <nav className="mobile-menu header-mobile z-60 sticky top-0 left-0 right-0 border-b border-gray-200 bg-white bg-opacity-95 lg:hidden ">
                <div className="relative z-20 flex items-center pt-1 pb-1 pl-4 pr-1 w-full">
                    <div className="flex-none">
                        <Link><span to="/" aria-label="Asia Target" className="block transition-opacity hover:opacity-90"></span></Link>
                    </div>
                    <div className="flex-1 pl-4 pr-1">
                        <form>
                            <div className="relative">
                                <input type="text" placeholder="What are you looking for..." className="items-center justify-center block w-full px-3 py-1 bg-white border border-gray-300 rounded h-9" />
                                <button type="submit" aria-label="Search" className="absolute border-none outline-none opacity-50 right-2 top-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 ">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="flex-none">
                        <button onClick={toggle} className="mobile-menu__button flex h-11 w-12 cursor-pointer items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-7 w-7">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
            {
                hide ? "" : renderMenu()
            }
        </>
    );
};

export default NavBar_m;
