import React from "react";
import services from "../../database/product.json"
import { Link } from "react-router-dom";

const ServiceBanner = () => {
    const background = {
        "backgroundImage": "url(https://ik.imagekit.io/asiatarget/asia_target/1.jpg?updatedAt=1679923225662)",
        "zIndex": "1"
    }
    const renderProduct0 = () => {
        let productList = [];
        for (let j = 0; j < 5; j++) {
            if (!services[0].product[j]) {
                productList.push(
                    <div key={`feature-image0--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src="https://placehold.co/600x400?text=placeholder" alt={`placeholder`} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            } else {
                productList.push(
                    <div key={`feature-image0--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src={services[0].product[j].featured_image} alt={services[0].product[j].title} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            }
        }
        return productList;
    }
    const renderProduct1 = () => {
        let productList = [];
        for (let j = 0; j < 5; j++) {
            if (!services[1].product[j]) {
                productList.push(
                    <div key={`feature-image1--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src="https://placehold.co/600x400?text=placeholder" alt={`placeholder`} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            } else {
                productList.push(
                    <div key={`feature-image1--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src={services[1].product[j].featured_image} alt={services[1].product[j].title} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            }
        }
        return productList;
    }
    const renderProduct2 = () => {
        let productList = [];
        for (let j = 0; j < 5; j++) {
            if (!services[2].product[j]) {
                productList.push(
                    <div key={`feature-image2--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src="https://placehold.co/600x400?text=placeholder" alt={`placeholder`} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            } else {
                productList.push(
                    <div key={`feature-image2--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src={services[2].product[j].featured_image} alt={services[2].product[j].title} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            }
        }
        return productList;
    }
    const renderProduct3 = () => {
        let productList = [];
        for (let j = 0; j < 5; j++) {
            if (!services[3].product[j]) {
                productList.push(
                    <div key={`feature-image3--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src="https://placehold.co/600x400?text=placeholder" alt={`placeholder`} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            } else {
                productList.push(
                    <div key={`feature-image3--item-${j}`} className={`absolute top-0 aspect-[2/3] h-full drop-shadow poster-${j+1}`}>
                        <img src={services[3].product[j].featured_image} alt={services[3].product[j].title} loading="lazy" className="absolute inset-0 h-full w-full rounded-lg object-cover" />
                        <div className="box-shadow absolute inset-0 z-[1] rounded-lg bg-transparent"></div>
                    </div>
                )
            }
        }
        return productList;
    }
    return (
        <section className="service--banner">
            <div className="absolute top-0 h-52 w-full bg-cover bg-no-repeat object-cover" style={background}>
                <div className="absolute inset-0 bg-gradient-to-t from-bluesky-900 via-bluesky-900/90 to-black/50"></div>
                <div className="relative top-28 m-6">
                    <h1 className="pb-2 text-3xl font-bold text-white sm:text-center md:text-4xl md:leading-tight">
                        Asia Target Services
                    </h1>
                    <p className="relative mx-auto max-w-2xl font-normal text-white/80 sm:text-center sm:text-lg ">
                        Asia Target Services
                        Asia Target helps business ensure that their products meet the necessary safety and quality requirements.
                    </p>
                </div>
            </div>
            <div className="relative overflow-hidden pb-10 pt-36 md:pb-16 md:pt-48 bg-bluesky-900">
                <div className="mx-auto w-full max-w-6xl md:px-8 lg:px-8 lg:py-8 px-6 py-6">
                    <div className="relative mt-10 md:mt-10">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-4 lg:grid-cols-4">
                            <Link to="">
                                <div className="group relative transition delay-200 ease-in-out ">
                                    <div className="relative aspect-[4/3] cursor-pointer overflow-hidden rounded-lg outline-white/30 group-hover:outline ">
                                        <div className="relative cursor-pointer overflow-hidden rounded-lg poster-square aspect-[4/3]">
                                            {renderProduct0()}
                                        </div>
                                    </div>
                                    <h3 className="mt-3 cursor-pointer font-bold leading-tight group-hover:underline md:mt-3 text-white text-xl">Testing</h3>
                                    <p className="mt-2 break-words text-sm text-gray-300 line-clamp-3">
                                        Deliver accurate and reliable results wit our state-of-the-art facilities and skilled technicians.
                                    </p>
                                </div>
                            </Link>
                            <Link to="">
                                <div className="group relative transition delay-200 ease-in-out ">
                                    <div className="relative aspect-[4/3] cursor-pointer overflow-hidden rounded-lg outline-white/30 group-hover:outline ">
                                        <div className="relative  cursor-pointer overflow-hidden rounded-lg poster-square aspect-[4/3]">
                                            {renderProduct1()}
                                        </div>
                                    </div>
                                    <h3 className="mt-3 cursor-pointer font-bold leading-tight group-hover:underline md:mt-3 text-white text-xl">Inspection</h3>
                                    <p className="mt-2 break-words text-sm text-gray-300 line-clamp-3">
                                        Uphold the quality and safety of your products with our experienced team, cutting-edge technology, and attention to detail. We ensure your items meet the highest standards.
                                    </p>
                                </div>
                            </Link>
                            <Link to="">
                                <div className="group relative transition delay-200 ease-in-out ">
                                    <div className="relative aspect-[4/3] cursor-pointer overflow-hidden rounded-lg outline-white/30 group-hover:outline ">
                                        <div className="relative  cursor-pointer overflow-hidden rounded-lg poster-square aspect-[4/3]">
                                            {renderProduct2()}
                                        </div>
                                    </div>
                                    <h3 className="mt-3 cursor-pointer font-bold leading-tight group-hover:underline md:mt-3 text-white text-xl">
                                        Certification & Audit
                                    </h3>
                                    <p className="mt-2 break-words text-sm text-gray-300 line-clamp-3">
                                        Achieve Product Success and Compliance with Our Certification & Audit Services. Let our experienced team maximize your potential and stay ahead in the market with our comprehensive certification and audit solutions.
                                    </p>
                                </div>
                            </Link>
                            <Link to="">
                                <div className="group relative transition delay-200 ease-in-out ">
                                    <div className="relative aspect-[4/3] cursor-pointer overflow-hidden rounded-lg outline-white/30 group-hover:outline ">
                                        <div className="relative  cursor-pointer overflow-hidden rounded-lg poster-square aspect-[4/3]">
                                            {renderProduct3()}
                                        </div>
                                    </div>
                                    <h3 className="mt-3 cursor-pointer font-bold leading-tight group-hover:underline md:mt-3 text-white text-xl">
                                        Consultation
                                    </h3>
                                    <p className="mt-2 break-words text-sm text-gray-300 line-clamp-3">
                                        Unleash Your Business Potential with Expert Consultation Services. Our seasoned experts are ready to guide your business towards new heights of success. Tailored solutions, data-driven strategies, and industry insights will help you unlock your business true potential.
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceBanner;
