import React, { useState } from "react";
import { Link } from "react-router-dom";
import services from "../../database/product.json";

const ServiceTab = () => {
    const defaultStyle = {
        "boxSizing": "border-box",
        "display": "block",
        "overflow": "hidden",
        "width": "initial",
        "height": "initial",
        "background": "none",
        "opacity": "1",
        "border": "0",
        "margin": "0",
        "padding": "0",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    }
    const defaultImg = {
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0",
        "boxSizing": "border-box",
        "padding": "0",
        "border": "none",
        "margin": "auto",
        "display": "block",
        "width": "0",
        "height": "0",
        "minWidth": "100%",
        "maxWidth": "100%",
        "minHeight": "100%",
        "maxHeight": "100%",
        "objectFit": "contain"
    }
    const [active, setActive] = useState(0);
    const toggle = index => event => setActive(index);
    const renderButton = () => {
        let buttons = [];
        for (let i = 0; i < services.length; i++) {
            buttons.push(
                <button onClick={toggle(i)} key={"services--tab--" + i} aria-controls={"tabs--panel--" + i} tole="button" tabIndex={i} className="inline-block whitespace-nowrap px-1.5 text-base font-semibold outline-none ring-0" data-reach-tab={i} data-orientation="horizontal" type="button">
                    <span style={{"pointerEvents": "none"}} className={`flex cursor-pointer items-center justify-center rounded-3xl border border-gray-300 px-4 pb-1 pt-1.5 text-sm hover:border-bluesky-600 hover:text-bluesky-600 sm:text-md md:mb-3  text-gray-500 ${active === i ? "border-bluesky-500 bg-bluesky-50 text-bluesky-600" : "text-gray-500"}`}>
                        <span className="relative mr-1 -mt-0.5 aspect-1 w-6 overflow-hidden">
                            <span style={defaultStyle}>
                                <img alt={services[i].service_name} src={`./svg/${services[i].service_icon}`} decoding="async" data-nimg="fill" style={defaultImg} />
                                <noscript>
                                    <img alt={services[i].service_name} src={services[i].service_icon} decoding="async" data-nimg="fill" style={defaultImg} />
                                </noscript>
                            </span>
                        </span>{services[i].service_name}
                    </span>
                </button>
            )
        }
        return buttons;
    }
    const renderTab = () => {
        let tablist = [];
        for (let i = 0; i < services.length; i++) {
            tablist.push(
                <div key={`tabs--panel--${i}`} aria-labelledby={`tabs--tab--${i}`} role="tabpanel" tabIndex={i} className={active === i ? "block" : "hidden"} data-reach-tab-panel="">
                    <div className="grid grid-cols-12 gap-5 sm:rounded-2xl sm:bg-bluesky-50 sm:py-6 sm:px-7">
                        <div className="col-span-12 sm:order-1 sm:col-span-6 lg:col-span-7">
                            <div className="relative aspect-[1/0.56] overflow-hidden rounded-md bg-gray-200">
                                <span style={defaultStyle}>
                                    <img alt={`${services[i].service_name} services`} src={`${services[i].service_image}`} decoding="async" data-nimg="fill" style={defaultImg} />
                                    <noscript>
                                        <img alt={`${services[i].service_name} services`} src={`${services[i].service_image}`} decoding="async" data-nimg="fill" style={defaultImg} />
                                    </noscript>
                                </span>
                            </div>
                        </div>
                        <div className="col-span-12 text-left sm:col-span-6 lg:col-span-5">
                            <h3 className="mb-2 text-center text-xl sm:text-left sm:text-2xl">
                            <span className="font-bold">{services[i].service_name}</span>: {services[i].service_header}
                            </h3>
                            <div className="mt-5 flex flex-row justify-center sm:justify-start">
                                <span>{services[i].service_description}</span>
                            </div>
                            <div className="mt-5 text-center sm:text-left">
                                <Link><span title="TÌM HIỂU THÊM" target="_self" className="inline-block rounded-3xl bg-bluesky-600 py-2 px-8 font-bold text-white duration-500 hover:bg-bluesky-500" href="/Link">FIND OUT MORE</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return tablist;
    }
    return (
        <section className="services-tab bg-white pb-8">
            <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div data-reach-tab-panels="">
                    <div className="service__tabs mx-auto sm:max-w-5xl">
                        <div data-orientation="horizontal">
                            <div className="relative -mx-5 sm:mx-0">
                                <div role="tablist" aria-orientation="horizontal" className="service__buttons relative sm: sm:w-100 -ml-2 block overflow-scroll whitespace-nowrap px-5 py-4 text-center sm:ml-0 sm:whitespace-normal sm:pb-1" data-reach-tab-list="">
                                    {renderButton()}
                                </div>
                            </div>
                            <div className="service__content">
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceTab;