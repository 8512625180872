import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    const defaultStyle = {
        "boxSizing": "border-box",
        "display": "block",
        "overflow": "hidden",
        "width": "initial",
        "height": "initial",
        "background": "none",
        "opacity": "1",
        "border": "0",
        "margin": "0",
        "padding": "0",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    }
    const defaultImg = {
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0",
        "boxSizing": "border-box",
        "padding": "0",
        "border": "none",
        "margin": "auto",
        "display": "block",
        "width": "0",
        "height": "0",
        "minWidth": "100%",
        "maxWidth": "100%",
        "minHeight": "100%",
        "maxHeight": "100%",
        "objectFit": "contain"
    }
    return (
        <footer className="viewApp-hidden grid grid-cols-1 flex-wrap bg-gray-900">
            <div className="footer-main order-2 py-7 text-white md:order-1">
                <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8 grid grid-cols-2 gap-5 sm:gap-x-20 sm:gap-y-10 lg:flex lg:flex-nowrap lg:gap-14">
                    <div className="block grow md:border-none md:pb-0">
                        <div className="whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80">About Asia Target</div>
                        <div className="mt-3.5 text-sm">
                            <Link to="/termofservice"><span className="text-white text-opacity-50 duration-300 hover:text-opacity-100">Term of Service</span></Link>
                        </div>
                        <div className="mt-3.5 text-sm">
                            <Link to="/"><span className="text-white text-opacity-50 duration-300 hover:text-opacity-100">Home</span></Link>
                        </div>
                        <div className="mt-3.5 text-sm">
                            <Link to="/services"><span className="text-white text-opacity-50 duration-300 hover:text-opacity-100">Service</span></Link>
                        </div>
                        <div className="mt-3.5 text-sm">
                            <Link to="/blogs"><span className="text-white text-opacity-50 duration-300 hover:text-opacity-100">Blog</span></Link>
                        </div>
                        <div className="mt-3.5 text-sm">
                            <Link to="/about"><span className="text-white text-opacity-50 duration-300 hover:text-opacity-100">About</span></Link>
                        </div>
                    </div>
                    <div className=" md:order-none md:w-40 md:flex-none md:border-none md:pb-0">
                        <div className="whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80">Highlight Service</div>
                        <div className="flex flex-wrap">
                            <div className="mt-3.5 flex w-full text-sm">
                                <a href="Link" target="_blank" rel="noreferrer" className="item-center inline-flex text-white text-opacity-50 duration-300 hover:text-opacity-100">
                                    <div className="relative aspect-1 h-5 w-5 md:-mt-0.5 md:h-6 md:w-6">
                                        <span style={defaultStyle}>
                                            <img alt="consultation" src="./svg/consultation.png" decoding="async" data-nimg="fill" style={defaultImg} />
                                            <noscript>
                                                <img alt="consultation" src="./svg/consultation.png" decoding="async" data-nimg="fill" style={defaultImg} loading="lazy" />
                                            </noscript>
                                        </span>
                                    </div>
                                    <span className="block pl-2">Consultation</span>
                                </a>
                            </div>
                            <div className="mt-3.5 flex w-full text-sm">
                                <a href="Link" target="_blank" rel="noreferrer" className="item-center inline-flex text-white text-opacity-50 duration-300 hover:text-opacity-100">
                                    <div className="relative aspect-1 h-5 w-5 md:-mt-0.5 md:h-6 md:w-6">
                                        <span style={defaultStyle}>
                                            <img alt="testing" src="./svg/testing.png" decoding="async" data-nimg="fill" style={defaultImg} />
                                            <noscript>
                                                <img alt="testing" src="./svg/testing.png" decoding="async" data-nimg="fill" style={defaultImg} loading="lazy" />
                                            </noscript>
                                        </span>
                                    </div>
                                    <span className="block pl-2">Testing</span>
                                </a>
                            </div>
                            <div className="mt-3.5 flex w-full text-sm">
                                <a href="Link" target="_blank" rel="noreferrer" className="item-center inline-flex text-white text-opacity-50 duration-300 hover:text-opacity-100">
                                    <div className="relative aspect-1 h-5 w-5 md:-mt-0.5 md:h-6 md:w-6">
                                        <span style={defaultStyle}>
                                            <img alt="certificate" src="./svg/certificate.png" decoding="async" data-nimg="fill" style={defaultImg} />
                                            <noscript>
                                                <img alt="certificate" src="./svg/certificate.png" decoding="async" data-nimg="fill" style={defaultImg} loading="lazy" />
                                            </noscript>
                                        </span>
                                    </div>
                                    <span className="block pl-2">Certification & Audit</span>
                                </a>
                            </div>
                            <div className="mt-3.5 flex w-full text-sm">
                                <a href="Link" target="_blank" rel="noreferrer" className="item-center inline-flex text-white text-opacity-50 duration-300 hover:text-opacity-100">
                                    <div className="relative aspect-1 h-5 w-5 md:-mt-0.5 md:h-6 md:w-6">
                                        <span style={defaultStyle}>
                                            <img alt="inspection" src="./svg/inspection.png" decoding="async" data-nimg="fill" style={defaultImg} />
                                            <noscript>
                                                <img alt="inspection" src="./svg/inspection.png" decoding="async" data-nimg="fill" style={defaultImg} loading="lazy" />
                                            </noscript>
                                        </span>
                                    </div>
                                    <span className="block pl-2">Inspection</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 grow border-t border-gray-700 pt-5 sm:col-span-1 md:border-none md:pt-0">
                        <div className="border-b border-gray-700 pb-5 md:border-0 md:pb-0">
                            <div className="whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80">Asia Target HQ</div>
                            <div className="mt-2 text-sm md:mt-3.5">
                                <span className="text-white text-opacity-50">Address: </span>
                                <span className="inline text-white text-opacity-70 duration-300 hover:text-opacity-100">372/3 Điện Biên Phủ, P.17, Bình Thạnh, Tp. Hồ Chí Minh.</span>
                            </div>
                            <div className="mt-1 text-sm">
                                <span className="text-white text-opacity-50">Hotline : </span>
                                <a href="tel:02862735889" className="inline text-white text-opacity-70 duration-300 hover:text-opacity-100">+84 28 6273 5889</a>
                            </div>
                            <div className="mt-1 text-sm">
                                <span className="text-white text-opacity-50">Email : </span>
                                <a href="mailto:info@asiatarget.com.vn" className="inline text-white text-opacity-70 duration-300 hover:text-opacity-100">info@asiatarget.com.vn</a>
                            </div>
                        </div>
                        <div className="mt-4 border-b border-gray-700 pb-5 md:mt-8 md:border-none md:pb-0">
                            <div className="whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80">Working Hour</div>
                            <div className="mt-1 text-sm">
                                <span className="text-white text-opacity-50">Monday - Friday: 8:30 - 17:30</span>
                            </div>
                            <div className="mt-1 text-sm">
                                <span className="text-white text-opacity-50">Saturday - Sunday: closed</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 gap-8 sm:col-span-1 md:mt-0 md:flex md:flex-col md:flex-wrap md:justify-between ">
                        <div className="h-auto">
                            <div className="hidden whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80 md:block">
                                Find us on social media
                            </div>
                            <div className="mt-3.5 hidden flex-row items-stretch md:flex">
                                <ul className="item-center flex list-none flex-wrap space-x-4">
                                    <li className="inline-block">
                                        <a href="https://www.facebook.com/asiatarget.vn" target="_blank" rel="noreferrer" className="duration-300 hover:brightness-125">
                                            <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                                <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
									xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                                </span>
                                                <img alt="Facebook" src="./svg/facebook.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                                <noscript></noscript>
                                            </span>
                                        </a>
                                    </li>
                                    <li className="inline-block">
                                        <a href="https://www.linkedin.com/company/asia-target/" target="_blank" rel="noreferrer" className="duration-300 hover:brightness-125">
                                            <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                                <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
											xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                                </span>
                                                <img alt="Linkedin" src="./svg/linkedin.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                                <noscript></noscript>
                                            </span>
                                        </a>
                                    </li>
                                    <li className="inline-block">
                                        <a href="later" target="_blank" rel="noreferrer" className="duration-300 hover:brightness-125">
                                            <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                                <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
													xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                                </span>
                                                <img alt="Youtube" src="./svg/zalo.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                                <noscript></noscript>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="h-auto">
                            <div className="hidden whitespace-nowrap text-tiny font-bold uppercase text-white text-opacity-80 md:block">
                                Follow us on Facebook
                            </div>
                            <div className="md:justify-normal mt-6 flex items-center justify-between md:mt-3.5">
                                <a rel="noopener noreferrer" href="later" target="_blank" className=" flex w-28 items-center md:w-auto">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                        <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                            <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
																				xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27119%27%20height=%2745%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                        </span>
                                        <noscript></noscript>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap md:mt-3.5 md:space-x-1.5">
                    <div className="flex w-full justify-center text-center md:hidden">
                        <ul className="item-center flex list-none flex-wrap space-x-3.5">
                            <li>
                                <a href="https://www.facebook.com/asiatarget.vn" target="_blank" rel="noreferrer" className="flex duration-300 hover:brightness-125">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                        <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                            <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
											xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2736%27%20height=%2736%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                        </span>
                                        <img alt="Facebook" src="./svg/facebook.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/asia-target/" target="_blank" rel="noreferrer" className="flex duration-300 hover:brightness-125">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                        <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                            <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
											xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2736%27%20height=%2736%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                        </span>
                                        <img alt="Linkedin" src="./svg/linkedin.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="blank" target="_blank" rel="noreferrer" className="flex duration-300 hover:brightness-125">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "relative", "maxWidth": "100%" }}>
                                        <span style={{ "boxSizing": "border-box", "display": "block", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "maxWidth": "100%" }}>
                                            <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20
											xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2736%27%20height=%2736%27/%3e" style={{ "display": "block", "maxWidth": "100%", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px" }} />
                                        </span>
                                        <img alt="Zalo" src="./svg/zalo.svg" decoding="async" data-nimg="intrinsic" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
