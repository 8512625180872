import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Service from './components/Service';
import Blog from './components/Blog';
import NotFound from './components/NotFound';
import './components/css/index.css';

const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/about" element={<NotFound />} />
          <Route path="/blogs" element={<Blog />} />
       </Routes>
    </>
 );
};

export default App;