import React from "react";
import { Link, useLocation } from "react-router-dom";
import blogsPost from "../../database/blog.json"

const BlogsList = () => {
    const location = useLocation();
    const hightlight = blogsPost.find(i => i.highlight === true);
    const sidebar = blogsPost.filter(i => i.sidebar === true);
    const join_blogs = sidebar.concat(hightlight);
    const filtered_blogs = blogsPost.filter(i => !join_blogs.includes(i));
    console.log(filtered_blogs);
    const renderBlog = () => {
        let blogs = []
        let number = filtered_blogs.length;
        let numberPost = number > 10 ? 10 : filtered_blogs.length
        for (let i = 0; i < numberPost; i++) {
            blogs.push(
                <div key={`blog--post-${i}`} className="col-span-1 false false">
                    <article className="article-new relative article-type-feature grid content-start gap-x-4 gap-y-2 md:gap-1">
                        <div className="">
                            <div className="article-thumb relative overflow-hidden rounded-md bg-gray-100">
                                <Link className="stretched-link" to={filtered_blogs[i].content_url}></Link>
                                <div className="aspect-w-16 aspect-h-9">
                                    <span style={{ "boxSizing": "border-box", "display": "block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0" }}>
                                        <img alt={filtered_blogs[i].title} src={filtered_blogs[i].cover_image} decoding="async" data-nimg="fill" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%", "objectFit": "contain" }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="md:pt-1 pt-2 mt-1 flex flex-wrap items-center text-xs font-semibold text-gray-500">
                            <Link className="uppercase article-cate" style={{ "color": "#325C83" }} href={filtered_blogs[i].content_url}>{filtered_blogs[i].article}</Link>
                            <div className="mx-1 font-normal">·</div>
                            <div>{filtered_blogs[i].publish}</div>
                        </div>
                        <header>
                            <h3>
                                <Link className="article-title text-truncate-row font-bold leading-tight text-gray-800 hover:underline text-lg" to="/">{filtered_blogs[i].title}</Link>
                            </h3>
                        </header>
                    </article>
                </div>
            )
        }
        return blogs;
    }
    const renderSidebar = () => {
        let blogs = []
        let number = sidebar.length;
        let numberPost = number > 4 ? 4 : sidebar.length
        for (let i = 0; i < numberPost; i++) {
            blogs.push(
                <article className="article-type-prefer font-nunito overflow-hidden rounded-xl shadow-lg">
                    <div className="flex h-full flex-col flex-nowrap">
                        <div className="article-top">
                            <div className="article-thumb relative overflow-hidden rounded-md bg-gray-100 rounded-b-none">
                                <Link to="blank_" className="stretched-link"></Link>
                                <div className="aspect-[77/37] aspect-w-16 aspect-h-9">
                                    <span style={{ "boxSizing": "border-box", "display": "block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0" }}>
                                        <img alt={sidebar[i].title} src={sidebar[i].cover_image} decoding="async" data-nimg="fill" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%", "objectFit": "contain" }} />
                                        <noscript>
                                            <img alt={sidebar[i].title} src={sidebar[i].cover_image} decoding="async" data-nimg="fill" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%", "objectFit": "contain" }} loading="lazy" />
                                        </noscript>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="article-header grow p-4 pb-0">
                            <h5 className="article-title mb-2">
                                <Link to={sidebar[i].content_url}>{sidebar[i].title}</Link>
                            </h5>
                            <p className="article-short">{sidebar[i].article}</p>
                        </div>
                        <div className="article-footer p-4">
                            <div className="article-xemchitiet text-left">
                                <Link to={sidebar[i].content_url} className="flex font-semibold text-bluesky-700 transition-colors hover:text-bluesky-500">Read more <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-mr-1 block w-4">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </article>
            )
        }
        return blogs;
    }
    return (
        <main className="lg:mt-0 page-article">
            <div class=" sticky top-0 z-30 bg-white shadow">
                <div class="mx-auto w-full max-w-6xl md:px-8 lg:px-8 px-0">
                    <div class="relative ">
                        <div id="article_menu" class="flex w-full overflow-scroll pt-3 pl-5 text-sm md:pl-0">
                            <div class="nav-item">
                                <Link to="/" class="flex w-8 content-center justify-around whitespace-nowrap pr-4
                text-gray-800 hover:text-bluesky-700 md:pr-4">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "20px", "height": "19px", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "relative" }}>
                                        <img alt="Home" src="./home.png" decoding="async" data-nimg="fixed" class="img-black" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                </Link>
                            </div>
                            <div class="nav-item is-active">
                                <Link class="flex content-center justify-around whitespace-nowrap pl-3 pr-4 transition-all duration-150 ease-in-out font-bold text-bluesky-700" to={location.pathname}>
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "20px", "height": "20px", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "relative" }}>
                                        <img alt="Blog" src="./blog.png" decoding="async" data-nimg="fixed" class="img-black" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                    <span class="ml-1" style={{ "paddingTop": "1px" }}>Service</span>
                                </Link>
                            </div>
                            {/* <div class="nav-item cat-item ">
                                <a class="flex content-center justify-around whitespace-nowrap px-3 transition-all duration-150 ease-in-out
                    text-gray-800 hover:text-bluesky-700" href="">
                                    <span style={{ "boxSizing": "border-box", "display": "inline-block", "overflow": "hidden", "width": "20px", "height": "20px", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "relative" }}>
                                        <img alt="Thông cáo" src="https://homepage.momocdn.net/fileuploads/svg/momo-file-210929095438.svg" decoding="async" data-nimg="fixed" class="img-black" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%" }} />
                                    </span>
                                    <span class="pl-1" style={{ "paddingTop": "1px" }}>Thông cáo</span>
                                </a>
                            </div> */}
                            <div class="w-1">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div className="mt-5 grid grid-cols-1 pb-10 lg:mt-6 lg:grid-cols-3 lg:gap-6">
                    <div className="col-span-2">
                        <div className="undefined article-feature grid grid-cols-1 content-center gap-4 pb-7 md:grid-cols-2 md:gap-8">
                            <div className="col-span-1 md:col-span-2 md:mb-3 false">
                                <article className=" article-new relative article-type-big-feature grid content-start gap-x-4 gap-y-2 md:gap-1">
                                    <div class="highlight-blog">
                                        <div className="article-thumb relative overflow-hidden rounded-md bg-gray-100">
                                            <Link className="stretched-link" to="blank_"></Link>
                                            <div className="aspect-[77/37] aspect-w-16 aspect-h-9">
                                                <span style={{ "boxSizing": "border-box", "display": "block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0", "margin": "0", "padding": "0", "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0" }}>
                                                    <img alt={hightlight.title} src={hightlight.cover_image} decoding="async" data-nimg="fill" style={{ "position": "absolute", "top": "0", "left": "0", "bottom": "0", "right": "0", "boxSizing": "border-box", "padding": "0", "border": "none", "margin": "auto", "display": "block", "width": "0", "height": "0", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%", "objectFit": "contain" }} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:pt-1 pt-2 mt-1 flex flex-wrap items-center text-xs font-semibold text-gray-500">
                                        <Link className="uppercase article-cate" style={{ "color": "#27AE60" }} href="/">{hightlight.article}</Link>
                                        <div className="mx-1 font-normal">·</div>
                                        <div>{hightlight.publish}</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="relative inline-block h-4 w-4 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ "top": "-1px" }}>
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                                        </svg>
                                        <div className="mx-1 font-normal">·</div>1 <span className="hidden md:inline-block">&nbsp;lượt xem</span>
                                    </div>
                                    <header className="article-header">
                                        <h3>
                                            <Link className="article-title text-truncate-row font-bold leading-tight text-gray-800 hover:underline text-lg md:text-1.5xl" to="/">{hightlight.title}</Link>
                                        </h3>
                                    </header>
                                </article>
                            </div>
                            {renderBlog()}
                        </div>
                    </div>
                    <div className="border-t border-gray-300 pt-3 md:pt-0 lg:border-t-0 lg:border-l lg:pl-6">
                        <div style={{ "position": "sticky", "top": "100px" }}>
                            <div className="mt-1 rounded-lg pb-4 md:mt-3 md:pt-5 lg:mt-0 lg:pt-1">
                                <div className="undefined pb-4">
                                    <div className="flex md:pd-2 pb-4">
                                        <h2 className="flex items-center text-xl font-semibold">
                                            <img className="img-black mr-2" alt="sidebar" src="./star.png" loading="lazy" width="19" height="20" />
                                            Sidebar
                                        </h2>
                                    </div>
                                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-1">
                                        {renderSidebar()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default BlogsList;
