import React from "react";

const SmallBanner = () => {
    const defaultStyle = {
        boxSizing: "border-box",
        display: "block",
        overflow: "hidden",
        width: "initial",
        height: "initial",
        background: "none",
        opacity: 1,
        border: 0,
        margin: 0,
        padding: 0,
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
    }
    return (
        <section className="mx-auto w-full max-w-6xl px-5 pt-2 md:px-8 lg:px-8 sm:pt-12 bg-[#294B6B11]">
            <div className="hidden px-2 mt-10 relative lg:block">
                <div className="flex flex-row flex-wrap justify-center gap-x-20">
                    <div className="relative col-md-6">
                        <div className="sticky mb-14" style={{top: "120px"}}>
                            <div className="text-base text-green-custom">Business Streamlined</div>
                            <h2 className="text-3xl font-bold text-bluesky-custom my-2">
                                Make services, quality inspection request simple and available, as it should be.
                            </h2>
                            <p className="text-lg">
                                We are excited to introduce our new online ordering service at Asia Target.
                                Our user-friendly platform makes it easy for you to place your orders from the comfort of
                                your own computer or mobile device.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="s-item mb-14">
                            <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                                <span style={defaultStyle}>
                                    <img alt="Sand time" src="https://img.icons8.com/arcade/64/null/hourglass-sand-bottom.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                                </span>
                            </div>
                            <h3 className="stroke text-6xl font-bold my-5 col-span-1 justify-items-center">01</h3>
                            <h4 className="text-xl font-semibold my-2">
                                No more long contact time
                            </h4>
                            <p className="font-light text-base">
                                At Asia Target, we provide services that can be requested online, to help
                                business access anywhere, anytime with just a few click to select the suitable
                                services, ask any questions that may arise and receive a quote within a few hours.
                                Our services include product inspection, testing, certification,
                                and consultation.
                                <br/>Optimize your operations. Achieve success with Asia Target.
                            </p>
                        </div>

                        <div className="s-item mb-14">
                            <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                                <span style={defaultStyle}>
                                    <img alt="Clock" src="https://img.icons8.com/arcade/64/null/time-machine.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                                </span>
                            </div>
                            <h3 className="stroke text-6xl font-bold my-5 col-span-1 justify-items-center">02</h3>
                            <h4 className="text-xl font-semibold my-2">
                                Fast and reliable result
                            </h4>
                            <p className="font-light text-base">
                                Our streamlined process ensures that your order
                                is processed efficiently and accurately, with clear communication throughout the entire process.
                                Whether you're on-the-go or in the office, our online ordering service provides a convenient
                                and hassle-free experience.
                            </p>
                        </div>

                        <div className="s-item mb-14">
                            <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                                <span style={defaultStyle}>
                                    <img alt="Handshake" src="https://img.icons8.com/arcade/64/null/handshake.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                                </span>
                            </div>
                            <h3 className="stroke text-6xl font-bold my-5 col-span-1 justify-items-center">03</h3>
                            <h4 className="text-xl font-semibold my-2">Build trust, mitigate risks</h4>
                            <p className="font-light text-base">
                                Our mission is to facilitate a better world by assisting businesses in enhancing their productivity,
                                delivering high-quality outcomes, and operating with honesty and credibility.
                                Additionally, we contribute to building a safer world by guaranteeing that the places where people
                                live and work are safe and clean, and that the products they use or consume are secure.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="relative lg:hidden">
                <div className="px-2 mb-14" style={{top: "120px"}}>
                    <div className="text-base text-green-custom">Business Streamlined</div>
                    <h2 className="text-xl font-bold text-bluesky-custom my-2">
                        Make services request simple and available anywhere, as it should be
                    </h2>
                    <p className="text-base">
                        We are excited to introduce our new online ordering service at Asia Target.
                        Our user-friendly platform makes it easy for you to place your orders from the comfort of
                        your own computer or mobile device.
                    </p>
                </div>
                <div className="s-item mb-14 px-4">
                    <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                        <span style={defaultStyle}>
                            <img alt="Sand time" src="https://img.icons8.com/arcade/64/null/hourglass-sand-bottom.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                        </span>
                    </div>
                    <h3 className="text-lg font-semibold my-2">
                        No more waiting time
                    </h3>
                    <p className="font-light text-sm">
                        With just a few clicks, you can quickly select the services you need, ask any questions you may have to our
                        staff, and receive a quote within minutes.
                    </p>
                </div>

                <div className="s-item mb-14 px-4">
                    <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                        <span style={defaultStyle}>
                            <img alt="Clock" src="https://img.icons8.com/arcade/64/null/time-machine.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                        </span>
                    </div>
                    <h3 className="text-lg font-semibold my-2">
                        Fast and reliable result
                    </h3>
                    <p className="font-light text-sm">
                        Our streamlined process ensures that your order
                        is processed efficiently and accurately, with clear communication throughout the entire process.
                        Whether you're on-the-go or in the office, our online ordering service provides a convenient
                        and hassle-free experience.
                    </p>
                </div>

                <div className="s-item mb-14 px-4">
                    <div className="relative item_icon sm:block aspect-[1/1] w-16 h-16">
                        <span style={defaultStyle}>
                            <img alt="Handshake" src="https://img.icons8.com/arcade/64/null/handshake.png" decoding="async" data-nimg="fill" style={defaultStyle} />
                        </span>
                    </div>
                    <h3 className="text-lg font-semibold my-2">
                        Build trust, mitigate risks
                    </h3>
                    <p className="font-light text-sm">
                        Our mission is to facilitate a better world by assisting businesses in enhancing their productivity,
                        delivering high-quality outcomes, and operating with honesty and credibility.
                        Additionally, we contribute to building a safer world by guaranteeing that the places where people
                        live and work are safe and clean, and that the products they use or consume are secure.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SmallBanner;


