import React, { useState } from "react";
import services from "../../database/product.json"
import { Link } from "react-router-dom";

const ServiceList = () => {
    const collection = ["All", "Testing", "Inspection", "Certification & Audit", "Consultancy"];
    const [value, setValue] = useState(0);
    const toggle = index => event => setValue(index);
    let filter = services.filter(i => {
        if(value === 0) return i;
        return i.service_name === collection[value]
    });
    const renderProduct = () => {
        let productList = [];
        for (let i = 0; i < filter.length; i++) {
            for (let j = 0; j < filter[i].product.length; j++) {
                productList.push(
                    <div key={`product--items-${i}-${j}`} className="mb-5 w-full px-2.5 sm:w-1/2 xl:w-1/4">
                        <div className="shadow-hourly group h-full overflow-hidden rounded-md bg-white ">
                            <Link className="relative flex h-full flex-col " to="">
                                <div className="wrap-next-img relative aspect-[16/9] bg-gray-100">
                                    <span style={{ "boxSizing": "border-box", "display": "block", "overflow": "hidden", "width": "initial", "height": "initial", "background": "none", "opacity": "1", "border": "0px", "margin": "0px", "padding": "0px", "position": "absolute", "inset": "0px" }}>
                                        <img alt={filter[i].product[j].title} src={filter[i].product[j].featured_image} decoding="async" data-nimg="fill" className="bg-gray-100 duration-300 ease-in-out group-hover:scale-105" style={{ "position": "absolute", "inset": "0px", "boxSizing": "border-box", "padding": "0px", "border": "none", "margin": "auto", "display": "block", "width": "0px", "height": "0px", "minWidth": "100%", "maxWidth": "100%", "minHeight": "100%", "maxHeight": "100%", "objectFit": "cover" }} />
                                        <noscript></noscript>
                                    </span>
                                </div>
                                <div className="flex flex-1 flex-col px-3.5 py-2.5">
                                    <div className=" flex-1">
                                        <h3 className=" capitalize transition-colors line-clamp-2  group-hover:text-bluesky-600">
                                            {filter[i].product[j].title}
                                        </h3>
                                        <div className=" mt-1 block">
                                            <p className="text-tiny text-gray-500 line-clamp-3">
                                                {filter[i].product[j].description}
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-1 text-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-4 shrink-0 text-yellow-400">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                            <div className="pt-1 text-gray-500   ">
                                                <span className="text-yellow-600">5</span>
                                                <span className="text-tiny"> (loading ...)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 shrink-0">
                                        <div className="flex flex-wrap items-center ">
                                            <div className=" text-lg font-bold text-gray-800">${filter[i].product[j].price}<span className="ml-1 text-tiny font-normal text-gray-800 sm:text-sm">/manday</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                )
            }
        }
        return productList;
    }
    return (
        <section className="pb-10 sm:pt-5">
            <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div className="-mx-5 mb-5 block items-center justify-between sm:mx-0 sm:flex sm:space-x-5">
                    <div className="w-full sm:w-[calc(100%-257px)]">
                        <div className="soju-carousel relative z-0">
                            <ul className="flex w-full flex-row items-center justify-start space-x-2 overflow-hidden overflow-x-auto whitespace-nowrap bg-white py-3 px-2 sm:px-0 ">
                                <li onClick={toggle(0)} className={`cursor-pointer rounded-full border-2 bg-gray-100 py-2 px-5 text-sm uppercase leading-none ${value === 0 ? "pointer-events-none border-bluesky-400 bg-bluesky-50 font-bold text-bluesky-500" : "border-gray-100"}`}>All</li>
                                <li onClick={toggle(1)} className={`cursor-pointer rounded-full border-2 bg-gray-100 py-2 px-5 text-sm uppercase leading-none ${value === 1 ? "pointer-events-none border-bluesky-400 bg-bluesky-50 font-bold text-bluesky-500" : "border-gray-100"}`}>Testing</li>
                                <li onClick={toggle(2)} className={`cursor-pointer rounded-full border-2 bg-gray-100 py-2 px-5 text-sm uppercase leading-none ${value === 2 ? "pointer-events-none border-bluesky-400 bg-bluesky-50 font-bold text-bluesky-500" : "border-gray-100"}`}>Inspection</li>
                                <li onClick={toggle(3)} className={`cursor-pointer rounded-full border-2 bg-gray-100 py-2 px-5 text-sm uppercase leading-none ${value === 3 ? "pointer-events-none border-bluesky-400 bg-bluesky-50 font-bold text-bluesky-500" : "border-gray-100"}`}>Certification</li>
                                <li onClick={toggle(4)} className={`cursor-pointer rounded-full border-2 bg-gray-100 py-2 px-5 text-sm uppercase leading-none ${value === 4 ? "pointer-events-none border-bluesky-400 bg-bluesky-50 font-bold text-bluesky-500" : "border-gray-100"}`}>Consultation</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-none grow-0 justify-end border-y border-gray-200 sm:w-[220px] sm:border-0 ">
                        <div className="flex-1 sm:flex-none">
                            <input className="peer hidden" type="radio" name="radio-direct" id="hourlyModal" defaultChecked />
                            <label className="relative flex cursor-pointer items-center justify-center border-r border-gray-200 px-3 py-2.5 text-center text-sm text-gray-900 before:absolute before:-bottom-px before:left-0 before:h-0.5 before:w-full peer-checked:text-bluesky-600 peer-checked:before:bg-bluesky-600 sm:rounded-l-md sm:border-2 sm:border-r-0 sm:py-1.5 sm:px-3 sm:before:w-0 peer-checked:sm:border-r-2 peer-checked:sm:border-bluesky-400 peer-checked:sm:bg-bluesky-50" htmlFor="hourlyModal">
                                <span className="flex items-center justify-center text-sm ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="mr-1 h-4 w-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>Sort by </span>
                            </label>
                        </div>
                        <div className="flex-1 sm:flex-none">
                            <input className="peer hidden" type="radio" name="radio-direct" id="overNightModal" />
                            <label className="relative flex cursor-pointer items-center justify-center border-r border-gray-200 px-3 py-2.5 text-center text-sm text-gray-900 before:absolute before:-bottom-px before:left-0 before:h-0.5 before:w-full peer-checked:text-bluesky-600 peer-checked:before:bg-bluesky-600 sm:rounded-r-md sm:border-2 sm:border-l-0 sm:py-1.5 sm:px-3 sm:before:w-0 peer-checked:sm:border-l-2 peer-checked:sm:border-bluesky-400 peer-checked:sm:bg-bluesky-50" htmlFor="overNightModal">
                                <span className="flex items-center justify-center text-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="mr-1 h-4 w-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path>
                                    </svg>Sort by </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <div>
                        <div className="-mx-2.5 flex flex-wrap">
                            {renderProduct()}
                        </div>
                        <button type="button" className="flex items-center rounded-full border border-bluesky-600 py-1 pl-4 pr-6 font-semibold text-bluesky-700 transition-all hover:bg-bluesky-50 hover:text-bluesky-800 mx-auto mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 inline-block h-4 w-4 animate-bounce opacity-80" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                            </svg>Xem thêm </button>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default ServiceList;
