import React, { useState } from "react";

const ServiceTitle = () => {
    const serviceContent = "Asia Target helps businesses make sure their products meet all the necessary safety and quality requirements. We're experts in various industries like food, textiles, hardlines, and softlines, and we offer personalized solutions to clients all around the world. Our services cover things like checking products before they're shipped, visiting factories to make sure everything is in order, testing products in our labs, certifying products, and giving advice – just to name a few. Our skilled team of inspectors, auditors, and lab experts use the latest technology and equipment to carefully inspect and evaluate products. This way, we make sure everything meets the right standards and follows the rules."
    const [hide, setHide] = useState(true);
    const toggle = () => {
        setHide(s => !s);
    };
    const renderTruncate = () => {
        return (
            <div className="is-truncated relative">
                <p>
                    {serviceContent}
                </p>
            </div>
        )
    }
    const renderShow = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="jsx-df4f07e142feb149 relative inline-block h-5 w-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" className="jsx-df4f07e142feb149"></path></svg>
        )
    }
    const renderHide = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="jsx-df4f07e142feb149 relative inline-block h-5 w-5 rotate-180 transform"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" className="jsx-df4f07e142feb149"></path></svg>
        )
    }
    return (
        <section id="block-1912" data-index="0" data-type="1" className="">
            <div className="py-8 md:py-10 lg:py-14 landing:bg-transparent bg-white " style={{ "--grad-rgb-color": "250 250 250" }}>
                <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                    <div className="mb-5 text-center md:mb-8" id="section-html">
                        <h2 className="text-2xl font-bold landing:text-lpTitle lg:text-3xl text-blueskysky-600">
                            Bring you the best quality
                        </h2>
                    </div>
                    <div className="mx-auto max-w-4xl">
                        <div className="small service-page-long-content mt-5">
                            {
                                hide ? renderTruncate() : <p>{serviceContent}</p>
                            }
                            <div className="jsx-df4f07e142feb149 mt-3 text-center">
                                <button type="button" onClick={toggle} className="jsx-df4f07e142feb149 rounded-md py-1 px-3 font-semibold text-bluesky-500  hover:bg-bluesky-50">
                                    { hide ? `Show more` : `Hide` }
                                    { hide ? renderShow() : renderHide() }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceTitle;
