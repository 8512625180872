import React, { useState } from "react";

const Guide = () => {
    const defaultStyle = {
        "boxSizing": "border-box",
        "display": "block",
        "overflow": "hidden",
        "width": "initial",
        "height": "initial",
        "background": "none",
        "opacity": "1",
        "border": "0",
        "margin": "0",
        "padding": "0",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    }
    const content = [
        { name: "Search for service", content: "Click on “Services” tab on our homepage to view all services. Search and select your desire service." },
        { name: "Request a quote", content: "After select your service, select on 'Add to cart' to save it for later view or, select 'Request now' to proceed to payment.\nAt this point, one of our inspectors will be informed." },
        { name: "Service Delivery and Reporting", content: "Asia Target will then provide the service as agreed upon in the service agreement, which may include inspection, testing, verification, certification, or other services. \n\nOur team will provide a detailed report of the service provided, including the results of any tests or inspections and any recommendations for improvements or further actions." },
        { name: "Follow-up Actions", content: "If necessary, Asia Target may provide follow-up actions, such as re-testing or additional inspections to ensure that any issues identified have been resolved." },
        { name: "Invoicing", content: "After everything is settled, Asia Target will invoice the customer for the service provided based on the terms of the service agreement." }
    ];
    const [show, setShow] = useState(0);
    const toggle = index => event => setShow(index);
    const renderStepMb = () => {
        let steps = [];
        for (let i = 0; i < 5; i++) {
            steps.push(
                <button key={`guideMb--tab--${i}`} title={`guideMb--tab--${i}`} onClick={toggle(i)} className="guide-list-mb-item relative flex cursor-pointer flex-wrap items-center text-left">
                    <div className="flex-1 pl-4">
                        <div className="font-bold">Step {i + 1}</div>
                        <div className={`text-sm text-gray-500`}>{content[i].name}</div>
                    </div>
                    <div className="shrink-0 pl-2 text-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="transform-arrow h-4 w-4">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </div>
                    <div className={`guide-list-mb-description ${show === i ? "" : "hidden"} pl-4`}>
                        <div className="p-5 small mt-2 text-sm text-gray-500 bg-bluesky-50">
                            <p>{content[i].content}</p>
                        </div>
                    </div>
                </button>
            )
        }
        return steps;
    }
    const renderStep = () => {
        let steps = [];
        for (let i = 0; i < 5; i++) {
            steps.push(
                <div key={`guide--tab--${i}`} className="guide-list-item relative cursor-pointer pl-12">
                    <button title={`guide--tab--${i}`} onClick={toggle(i)} className={`guide-icon absolute top-0 left-0 flex h-8 w-8 items-center justify-center rounded-full text-md font-semibold transition-colors ${show === i ? "bg-bluesky-600" : "bg-[#dddddd]"} text-white`}>{i + 1}</button>
                    <div key={`guide--item--${i}`} className={`guide-header pt-1 text-md font-semibold ${show === i ? "text-bluesky-600" : "text-[#013a51]"}`}>{content[i].name}</div>
                    {
                        show === i ? <div className="guide-description fade small mt-2 text-sm text-gray-500 "><p>{content[i].content}</p></div> : ""
                    }
                </div>
            )
        }
        return steps;
    }
    const renderImg = () => {
        let source;
        switch (show) {
            case 0:
                source = "https://placehold.co/600x400?text=search+for+service";
                break;
            case 1:
                source = "https://placehold.co/600x400?text=request+a+quote";
                break;
            case 2:
                source = "https://placehold.co/600x400?text=delivery+and+reporting";
                break;
            case 3:
                source = "https://placehold.co/600x400?text=follow-up+actions";
                break;
            case 4:
                source = "https://placehold.co/600x400?text=invoicing";
                break;
            default:
                source = "https://placehold.co/600x400?text=404+not+found";
        }
        return (
            <span style={defaultStyle}>
                <img id="demo-website" alt="demo-website" src={source} loading="lazy" decoding="async" data-nimg="fill" style={defaultStyle} />
            </span>
        )
    }
    return (
        <section className="pt-2 sm:pt-12">
            <div className="mb-5 text-center md:mb-8">
                <h2 className="text-2xl font-bold lg:text-3xl text-prussian-500">
                    How to Request Asia Target Services
                </h2>
            </div>
            <div className="mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div className="relative px-2 lg:hidden">
                    <div className="py-8 md:py-10 lg:py-14 bg-bluesky-50">
                        <div className="mx-auto w-full px-5">
                            <div className="guide-list-mb grid grid-cols-1 gap-y-4">
                                {renderStepMb()}
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-center text-center lg:mt-10">
                        <a className="mx-auto flex w-fit items-center justify-center rounded-full border border-bluesky-600 px-5 pt-1.5 pb-1 font-semibold text-bluesky-700 transition-all hover:bg-bluesky-50 hover:text-bluesky-800" href="/tin-tuc#home">Read more <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="ml-1 w-4">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                        </a>
                    </div>
                </div>

                <div className="relative flex flex-row flex-wrap items-center justify-center px-2 py-10 hidden lg:block">
                    <div className="pt-5 mx-auto flex flex-nowrap justify-center ">
                        <div className="w-1/2 flex-none">
                            <div className="relative hidden sm:block aspect-[16/9] border-2 border-bluesky-900">
                                {renderImg()}
                            </div>
                        </div>
                        <div className="flex-auto pl-10 ">
                            <div className="guide-list">
                                {renderStep()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Guide;