import React from "react";
import NavBar from './global/Navbar';
import NavbarM from './global/NavbarM';
import ServiceBanner from "./services/ServiceBanner";
import ServiceTitle from "./services/ServiceTitle";
import ServiceList from "./services/ServiceList";
import Footer from "./index/Footer";

const Services = () => {
    const RenderMobile = () => {
        const [width, setWidth] = React.useState(window.innerWidth);
        React.useEffect(() => {
            const handleResizeWindow = () => setWidth(window.innerWidth);
            window.addEventListener("resize", handleResizeWindow);
            return () => {
                window.removeEventListener("resize", handleResizeWindow);
            };
        }, []);
        if (width >= 1024) return (<NavBar />);
        return (<NavbarM />);
    }
    return (
        <>
        { RenderMobile() }
        <ServiceBanner />
        <ServiceTitle />
        <ServiceList />
        <Footer />
        </>
    );
};

export default Services;
