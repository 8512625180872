import React from "react";
import { Link } from "react-router-dom";

const NavBarNS = () => {
    return (
        <nav className="scroll_down navbar-desktop relative">
            <section className="flex_content flex-1">
                <Link to="/"><span aria-label="Asia Target" className="logo font-semibold">Asia Target</span></Link>
            </section>
            <section className="flex_content nav_content flex-auto text-sm" id="nav_content">
                <ul className="flex">
                    <li className="dropdown">
                        <Link to="/"><span className="menu text-white hover:border-b hover:border-white">Home</span></Link>
                    </li>
                    <li>
                        <Link to="/services"><span className="menu text-white hover:border-b hover:border-white">Services</span></Link>
                    </li>
                    <li>
                        <Link to="/about"><span className="menu text-white hover:border-b hover:border-white">About</span></Link>
                    </li>
                    <li>
                        <Link to="/blogs"><span className="menu text-white hover:border-b hover:border-white">Blogs</span></Link>
                    </li>
                </ul>
            </section>
        </nav>
    );
};

export default NavBarNS;
