import React, { useState } from "react";
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Banner = () => {
    let [isNext, setIsNext] = useState(true);
    let [isPrev, setIsPrev] = useState(false);
    const renderButton = (index) => {
        if (index.activeIndex === index.slides.length - 1) {
            setIsNext(false)
            setIsPrev(true)
        } else if (index.activeIndex === 0) {
            setIsNext(true)
            setIsPrev(false)
        } else   {
            setIsNext(true)
            setIsPrev(true)
        }
    }
    return (
        <div className="swiper-hero relative mb-3 max-w-full sm:mb-4 md:mb-6 lg:mb-7">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                slidesPerView={1}
                navigation={{
                    nextEl: '.button-next',
                    prevEl: '.button-prev'
                }}
                scrollbar={{ draggable: true }}
                onSlideChange={(index) => {
                    renderButton(index);
                }}
            >
                <SwiperSlide>
                    <div className="relative aspect-video overflow-hidden sm:aspect-[96/37]">
                        <a target="_blank" rel="noreferrer" href="placeholder">
                            <picture>
                                <source srcSet="./2.png" />
                                <img src="./2.png" loading="lazy" className="absolute inset-0 w-full " alt="Service-1" />
                            </picture>
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="relative aspect-video overflow-hidden sm:aspect-[96/37]">
                        <a target="_blank" rel="noreferrer" href="placeholder">
                            <picture>
                                <source srcSet="./1.png" />
                                <img src="./1.png" loading="lazy" className="absolute inset-0 w-full " alt="Service-2" />
                            </picture>
                        </a>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="absolute left-0 right-0 top-1/2 mx-auto w-full max-w-6xl px-5 md:px-8 lg:px-8">
                <div className={isPrev ? "button-prev button-swiper absolute left-4 top-1/2 z-20 flex h-9 w-9  -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-gray-200 bg-white text-gray-700 shadow-md transition-all hover:opacity-90 md:h-11 md:w-11" : "button-prev hidden"} key={1}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 md:h-7 md:w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                    </svg>
                </div>
                <div className={isNext ? "button-next button-swiper absolute right-4 top-1/2 z-20 flex  h-9 w-9 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-gray-200 bg-white text-black shadow-md transition-all hover:opacity-90 md:h-11 md:w-11" : "button-next hidden"} key={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 md:h-7 md:w-7">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Banner;
